

// Minibutton overrides
.btn-sm {
    padding: 2px 10px;
}

// Full-width button
.btn-block {
    display: block;
    width: 100%;
    text-align: center;
}

// main btn
.btn {
    outline: none;
    box-shadow: none;
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-family: Inter !important;
    border-radius: 4px;
    &:hover,
    &:active,
    &:focus {
        outline: none;
        box-shadow: none;
        // color: #11435a;
        // border: 1px solid #11435a;
        // background: #fff;
    }
}

.btn-primary {
    background: #468BE8;
    box-shadow: 0px 4px 4px rgba(70, 139, 232, 0.25);
    border: 0.5px solid #468BE8;
    color: #ffff;
    &:hover,
    &:active,
    &:focus {
        outline: none;
        box-shadow: none;
        border: 0.5px solid #468BE8;
        background: #468BE8;
    }
}
.btn-outline {
    background: #FFFFFF;
    border: 0.5px solid #CBC6C6;
    box-sizing: border-box;
    color: #6C757D;
    &:hover,
    &:active,
    &:focus {
        outline: none;
        box-shadow: none;
        background: #FFFFFF;
        border: 0.5px solid #CBC6C6;
        color: #6C757D;
    }
}




.btn-outline-primary {
    background: transparent;
    border: 0.5px solid $dark-grey-font;
    box-sizing: border-box;
    color: $dark-grey-font;
    padding: 9px 12px;
    &:hover,
    &:active,
    &:focus {
        outline: none;
        box-shadow: none;
        background: transparent;
        border: 0.5px solid $dark-grey-font;
        color: $dark-grey-font;
    }
}


.editor-btn {
    border: 0.5px solid #CBC6C6;
    box-sizing: border-box;
    background: #FFFFFF;
    padding: 5px;
    min-height: 24px;
    max-height: 24px;
    &.active,
    &:hover {
        background: #dfdfdf;
    }
}
.br-2 {
    border-radius: 2px;
}
.Decoration-btn .editor-btn  {
    min-width: 24px;
    min-height: 24px;
    max-height: 24px;
}
.render-btn{
    height: 40px;
}
.cancel-button{
    position: absolute;
    top: 50% !important;
    left: 150px !important;
    z-index: 100;
    transform: translateY(-50%);
    width: fit-content;
}