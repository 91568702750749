.pb-tbl-blk{
    .render-table{
        @include border-radius(2px);
        margin:8px 0;
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr 2fr !important;
        grid-auto-flow: row dense;
        grid-auto-rows: minmax(40px,auto);
        grid-gap:10px;
        background: #F9F9F9;
        border: 0.5px solid rgba(#CBC6C6,.5);
    }
    .render-list-table{
        grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr 2.5fr !important;

    }
    .admin-render-list{
        grid-template-columns: 1.5fr 1.5fr 1fr 1fr 1fr 1fr 1fr 2.5fr !important;

    }
}