.forgt-wrapper {
  text-align: right;
  margin-top: 15px;
}
.input-error-msgs {
  font-size: 13px;
  font-weight: 300;
  color: white;
}
.App {
  font-family: sans-serif;
  text-align: center;
}
#password-dummy {
  position: absolute;
  top: 21px;
  left: 45px;
  color: #fff;
}
.password-input {
  color: transparent;
}
.password-holder.active #password-dummy {
  display: none;
}
.password-holder.active .show-pass-eye {
  display: block;
}
.password-holder.active .hide-pass-eye {
  display: none;
}
.show-pass-eye {
  display: none;
}
.show-pass-eye svg {
  width: 20px;
  fill: none;
  stroke: #8d8d8d;
}
.reactEasyCrop_Container {
  /* position: relative !important;
  height: 320px !important;
  width: 480px !important; */
}
/* .MuiInputBase-root{
  color: #fff !important;
} */
.WAMuiChipInput-underline-31:after {
  /* border-bottom: none !important; */
  width: 100% !important;
  height: 100% !important;
  border: 1px solid #468be8 !important;
  transition: none !important;
}
.WAMuiChipInput-underline-31:before {
  /* border-bottom: none !important; */
  display: none !important;
}
.style-checkbox {
  padding: 0 !important;
}
video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.cursor-pointer {
  cursor: pointer;
}
/* .react-datepicker__month-container {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #2e2e2e;
  color: white;
  border: 0px;
  border-radius: 0;
  display: inline-block;
  position: relative;
} */
.usage-list-warper.template-listing {
  /* max-height: calc(100vh - 221px);
  min-height: calc(100vh - 221px);
  overflow-y: unset;
  overflow-x: unset; */
  max-height: calc(100vh - 310px);
  min-height: calc(100vh - 310px);
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}
.usage-list-warper.template-listing.total-template-list .usage-block {
  background: #ebebeb;
  grid-template-columns: 50px 80px 1fr 1fr 100px 100px 100px 120px 120px 120px 100px 100px 100px 130px 100px 130px 130px 100px 100px;
}
.dark .usage-list-warper.template-listing.total-template-list .usage-block {
  background: #2e2e2e;
  grid-template-columns: 50px 80px 1fr 1fr 100px 100px 100px 120px 120px 120px 100px 100px 100px 130px 100px 130px 130px 100px 100px;
}
.usage-list-warper.template-listing .usage-block {
  background: #ebebeb;
  grid-template-columns: 50px 80px 1fr 1fr 100px 100px 100px 120px 120px 120px 100px 100px 100px 100px;
}

.dark .usage-list-warper.template-listing .usage-block {
  background: #2e2e2e;
  grid-template-columns: 50px 80px 1fr 1fr 100px 100px 100px 120px 120px 120px 100px 100px 100px 100px;
}
/* grid-template-columns: 0.5fr 1.5fr 1fr 1fr 1fr 1fr 1.2fr 1fr 1fr 1fr 1fr 1fr; } */
.dark .total-template-count,
.dark .view-all {
  color: #c0c2c3;
}
.template-wrapper.pb-wrapper .table-wraper {
  overflow-y: auto;
}
.MuiPaginationItem-root {
  background: #eee;
  color: #7b7b7b !important;
  font-weight: bold !important;
}
.dark .MuiPaginationItem-root {
  background: #2e2e2e;
  color: #c0c2c3 !important;
  font-weight: bold !important;
}
.total-template-count,
.view-all {
  color: var(--font-color);
}
.dark .custom-search input.srch-input::placeholder {
  color: #646464 !important;
}
.custom-search input.srch-input::placeholder {
  color: #a2a2a2 !important;
}
.usage-list-warper.settings-listing .usage-block {
  grid-template-columns: 0.6fr 0.6fr 0.6fr 0.6fr 0.6fr 0.6fr 1fr 0.7fr 0.6fr !important
}
.usage-list-warper.template-listing.settings-listing {
  min-width: unset;
}

.usage-list-warper.cluster-listing .usage-block {
  grid-template-columns: 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.5fr 0.4fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.2fr !important;
}

.usage-list-warper.vm-listing .usage-block {
  grid-template-columns: 0.7fr 0.7fr 0.7fr 0.7fr !important
}
.usage-list-warper.template-listing.vm-listing {
  min-width: unset;
  /* overflow-y:hidden !important  */
}
.usage-list-warper.cluster-usage .usage-block {
  grid-template-columns: 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 1fr 0.7fr !important
}
.usage-list-warper.cluster-usage.cluster-listing {
  min-width: unset;
  overflow-y:hidden !important
}
.usage-list-warper.template-listing.cluster-listing {
  min-width: unset;
  overflow-y:hidden !important 
}
.tracking-wrapper .tracking-inner .tracking-edit-list {
  max-height: 130px;
}
.assets-search-close .search-close {
  top: 10px;
}
/* .react-tel-input .form-control {
  background-color: rgb(242, 236, 236) !important;
  color: rgb(104, 7, 60);
  height: 40px !important;
  width: 120px !important;
}

.flag-dropdown {
  background-color: rgb(233, 228, 220) !important;
} */
.react-tel-input {
  width: inherit !important;
  margin-right: 15px;
}
.react-tel-input .form-control {
  height: 40px !important;
  max-width: 125px !important;
  background: #2e2e2e;
  color: #333;
  border: none !important;
}
.react-tel-input .flag-dropdown {
  border-radius: 4px !important;
  background: transparent !important;
}
.react-tel-input .selected-flag {
  padding: 0 0 0 11px;
  border-radius: 4px !important;
  background: transparent !important;
}

.dark .react-tel-input .form-control {
  background: #2e2e2e;
  color: #fff;
}
.asset-tags .panel-txtarea > div {
  overflow: auto;
}
.locktemplatesvg svg {
  fill: white;
  width: 15px;
  margin-left: 4px;
}
.border-orange {
  border: 1px solid orange;
}
.react-datepicker-popper {
  z-index: 99 !important;
}
.user-list-wrap .user-list-block.usg-sub-block .usage-head-label .css-vqmohf-MuiButtonBase-root-MuiRadio-root {
 
  padding: 0 !important;
}
.templatenamecut{
  display: inline-block;
  width: 96px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.active-screens{
  color:"red"
}
.crashed-list{
  text-align: center;
    width: 100%;
}
.refresh-button{
  position: absolute;
    right: 1px;
    top: 0;
    width: 21px;
    height: 21px;
    z-index: 99;
    cursor: pointer;
}
.down-width{
  max-height: 136px !important
}
.mod-slidename{
  color: white;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.preview-generate{
  font-size: 12px;
  width: 100%;
  text-align: center;
  color: #696464;
  font-weight: 500;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}
.render-table{
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 2fr !important;
}
.red-class{
  color: red;
}
